// 1:契約中
// 2:追加予定
// 0:削除予定
// -1:削除済
export default {
  NORMAL: {
    status: 1,
    text: '契約中',
    iconColorClass: 'secondary lighten-4',
    baseColorClass: 'secondary',
    colorId: '#26C6DA',
    descriptionTitle: '契約中の場合に選択します。',
    description: '',
  },
  PRE_IN: {
    status: 2,
    text: '追加予定',
    iconColorClass: 'blue lighten-4',
    baseColorClass: 'blue lighten-2',
    colorId: '#42A5F5',
    descriptionTitle: '賃貸借契約書を締結後、追加予定の契約者の場合に選択します。',
    description: '契約者の追加に関連する覚書テンプレートに、追加される契約者の情報を自動印字し、書類送信先として選択可能となります。\n契約者の追加に関連する覚書を締結後にステータスを「契約中」に変更することで、その他のテンプレートへ当該契約者の情報の自動印字および書類送信先の選択が可能となります。\n※賃貸借契約書が未締結の状態、または原契約情報の保存機能を利用していない場合は「契約中」を選択してください。',
  },
  PRE_OUT: {
    status: 0,
    text: '削除予定',
    iconColorClass: 'warning lighten-4',
    baseColorClass: 'warning lighten-2',
    colorId: '#BDBDBD',
    descriptionTitle: '賃貸借契約書を締結後、削除される契約者の場合に選択します。',
    description: '契約者の削除に関連する覚書テンプレートに、削除される契約者の情報を自動印字し、書類送信先として選択可能となります。\n契約者の削除に関連する覚書を締結後にステータスを「削除済」に変更することで、その他のテンプレートへ当該契約者の情報は自動印字されず、また、書類送信先で選択ができなくなります。\n※賃貸借契約書が未締結の状態、または原契約情報の保存機能を利用していない場合は「削除済」を選択してください。',
  },
  CANCEL: {
    status: -1,
    text: '削除済',
    iconColorClass: 'danger lighten-4',
    baseColorClass: 'danger lighten-2',
    colorId: '#26A69A',
    descriptionTitle: '削除済みの契約者の場合に選択します。',
    description: '契約書類セットの新規作成時、各種テンプレートへ当該契約者の情報は自動印字されず、また、書類送信先で選択ができなくなります。',
  },
}
