export default [
  {
    // 重要事項説明
    value: 1,
    text: '申込',
    iconColorClass: 'green',
    description: '申込時期に必要な書類セット。',
  },
  {
    // 契約会
    value: 2,
    text: '契約',
    iconColorClass: 'blue',
    description: '契約時期に必要な書類セット。',
  },
  {
    // 個別対応
    value: 3,
    text: '個別対応',
    iconColorClass: 'pink',
    description: 'その他の書類セット。',
  },
]
