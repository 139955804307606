export default {
  PRE_APPLYING: {
    status: 0,
    text: '申込',
    iconColorClass: 'cyan lighten-4',
    baseColorClass: 'cyan lighten-1',
    colorId: '#26C6DA',
    description: '申込をしたお客様',
  },
  PRE_DECLINED: {
    status: 1,
    text: '申込辞退',
    iconColorClass: 'blue lighten-4',
    baseColorClass: 'blue lighten-1',
    colorId: '#42A5F5',
    description: '申込を辞退したお客様',
  },
  PRE_WINNING: {
    status: 2,
    text: '契約確定',
    iconColorClass: 'grey lighten-4',
    baseColorClass: 'grey lighten-1',
    colorId: '#BDBDBD',
    description: '契約が確定したお客様',
  },
  CONTRACT_CONTINUE: {
    status: 3,
    text: '契約',
    iconColorClass: 'teal lighten-4',
    baseColorClass: 'teal lighten-1',
    colorId: '#26A69A',
    description: '賃貸契約をしたお客様',
  },
  CONTRACT_CONFIRMED: {
    status: 4,
    text: '引渡済',
    iconColorClass: 'green lighten-4',
    baseColorClass: 'green lighten-1',
    colorId: '#66BB6A',
    description: '引渡済のお客様',
  },
  CONTRACT_CANCELED: {
    status: 5,
    text: '解約',
    iconColorClass: 'grey lighten-4',
    baseColorClass: 'grey lighten-1',
    colorId: '#BDBDBD',
    description: '解約をしたお客様',
  },
}
