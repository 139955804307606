import AUTHRIZATIONS from '@/data/constAuthorization'
import CONTRACT_STATUSES from '@/data/constContractStatus'
import DOCUMENT_STATUSES, { DOCUMENT_STATUSES_VERIFICATION_PROCESSING } from '@/data/constDocumentStatus'
import CUSTOMER_STATUSES from '@/data/constCustomerStatus'
import CONTRACT_PHASES from '@/data/constContractPhase'
import MESSAGE from '@/data/constMessage'
import COOKIE from '@/data/constCookie'
import AWS from '@/data/constAws'
import MFA from '@/data/constMfa'
import HINT from '@/data/constHint'
import CONTRACT from '@/data/constContract'
import APPROVAL_GROUP from '@/data/constApprovalGroup'
import RULES from '@/data/constRules'
import DOCUMENT_EDIT from '@/data/constDocumentEdit'
import CUSTOMER from '@/data/constCustomer'
import USER from '@/data/constUser'
import DISTRIBUTION_DOCUMENT from '@/data/constDistributionDocument'
import LOAN_SCREENING_APPLICATION from '@/data/constLoanScreeningApplication'
import PAGINATION from '@/data/constPagination'

const CONST = {
  APP_NAME: 'ムスベル for 新築分譲',
  APP_NAME_EN: 'Musubell',
  DATE_FORMAT: 'YYYY/MM/DD',
  DATE_FORMAT_INPUT: 'YYYY-MM-DD', // デートピッカーに渡す日付のフォーマット
  DATE_FORMAT_TIME: 'YYYY/MM/DD HH:mm', // メール送信日時
  DATE_FORMAT_MONTH: 'YYYY/MM', // デートピッカーで使用する年月
  DATE_FORMAT_PARAMS: 'YYYYMM', // APIのパラメータで使用する年月（スラッシュ区切り無し）
  DATE_FORMAT_PARAMS_DAY: 'YYYYMMDD', // APIのパラメータで使用する年月日（スラッシュ区切り無し）
  MIN_DATE: '1800/01/01', // デートピッカーなどで扱う最小日付
  MAX_DATE: '2999/12/31', // デートピッカーなどで扱う最大日付
  COOKIE,
  AUTHRIZATIONS,
  CONTRACT_STATUSES,
  DOCUMENT_STATUSES,
  DOCUMENT_STATUSES_VERIFICATION_PROCESSING,
  CUSTOMER,
  CUSTOMER_STATUSES,
  MESSAGE,
  CONTRACT_PHASES,
  CONTRACT,
  TABLE: {
    // １ページあたりのアイテム数
    ITEMS_PER_PAGE: 20,
    // ページャーの表示ボタン数
    PAGINATION_VISIBLE_BTN_COUNT: 7,
  },
  DOCUMENT_FILE: {
    // 1ファルあたりの最大サイズ(20MB)
    // ※クラウドサインのファイル送信APIの制限上、余裕をもって1KB(1024Byte)減らしておく
    // 参考: https://app.swaggerhub.com/apis/CloudSign/cloudsign-web_api/0.20.0#/default/post_documents__documentID__files
    MAX_SIZE: 20 * 1024 * 1024 - 1024,
    // トータルファイルサイズ(50MB)
    // MAX_TOTAL_SIZE: 50000000,
    // 最大ファイル数
    MAX_COUNT: 100,
    // 選択上限（チェックボックス）
    MAX_SELECT_COUNT: 200,
  },
  ACCOUNT_TYPE: {
    USER: 1, // ユーザー
    CUSTOMER: 2, // 顧客（契約者、共有者）
    CUSTOMER_AGENT: 3, // 顧客代理人
  },
  AWS,
  CONTRACT_CUSTOMERS_FILE: 'musubell_customer_data.csv',
  CONTRACT_GRUOP_INFO_FILE: 'musubell_contract_data.csv',
  USER_INFO_FILE: 'musubell_user_data.csv',
  // 締結済み書類ダウンロードファイル名
  DOWNLOAD_CONCLUDED_FILE_NAME: 'contracts_concluded.zip',
  // FIPS保証証書発行依頼情報の一括(CSV)ダウンロードファイル名
  DOWNLOAD_FIPS_WARRANTY_ISSUE_INFO_CSV_FILE_NAME: 'musubell_fips_warranty_issue_data.csv',
  MFA,
  HINT,
  DELETE_HOMEBUILDER: '削除済宅建士',
  DELETE_USER: '削除済ユーザー',
  FILE_DROP_LABEL: 'ファイル選択',
  NOT_FOUND_PDF: '/pdf/PDFNotFound.pdf',
  APPROVAL_GROUP,
  RULES,
  DOCUMENT_EDIT,
  HOME_BUILDER_ID_PREFIX: 'homebuilder_',
  PARTNER_ID_PREFIX: 'partner_',
  IMG: {
    PDF_ICON: 'PDF_ICON',
    DOC_ICON: 'DOC_ICON',
    DRAG_ICON: 'DRAG_ICON',
  },
  TIMER: {
    STATUS: {
      SUCCEEDED: 'SUCCEEDED',
      RUNNING: 'RUNNING',
    },
    INTERVAL: 3000,
  },
  TOS_TYPE: {
    USER: 'U', // ユーザー
    CUSTOMER: 'C', // 顧客（契約者、共有者、代理人）
  },

  // ドロワーが設置される場所を定義
  DRAWER_SHOW_TYPE: {
    ON_PAGE: 'on_page', // ページ上（デフォルト）
    ON_FILE_PREVIEW: 'on_file_preview', // ファイルプレビュー上
  },

  // 監査ログダウンロードファイル名
  AUDIT_LOG_FILE_NAME: 'musubell_audit-log_{original_article_id}_{month}.zip',
  // 操作ログダウンロードファイル名
  OPERATION_LOG_FILE_NAME: 'musubell_operation-log_{month}.zip',
  // 認証メール：メッセージ
  AUTH_MAIL_INFO: '※認証メールの有効期限は10日間となります。',

  // テンプレートファイル（Word）
  TEMPLATE_FILE: {
    // 最大サイズ(10MB)
    MAX_SIZE: 10 * 1024 * 1024,
  },

  // 連結ファイル（PDF）
  CONCAT_PDF_FILE: {
    // 最大サイズ(18MB)
    MAX_SIZE: 18 * 1024 * 1024,
  },

  USER,
  // 非同期処理実行時のローディングメッセージ
  LOADING_MESSAGE: {
    ZIP_FILE: 'zipファイルを作成中です。最大で15分程かかる場合があります。',
    PDF_FILE: 'PDFファイルを作成中です。最大で15分程かかる場合があります。',
  },
  DISTRIBUTION_DOCUMENT,
  IDP_CONNECTED_TEXT: 'SSO連携済',
  LOAN_SCREENING_APPLICATION,

  // ページネーション
  PAGINATION,
}

export default CONST
