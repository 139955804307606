/*
 * システム設定のサイドメニュー定義
 */
export const MODE_SETTNGS = 'mode_settings'

// システム管理者用
export const settingsAdminItem = [
  {
    group: 'systems',
    subheader: 'システム設定',
    member: [
      {
        title: 'ユーザー管理',
        icon: 'mdi-account',
        group: 'systems',
        to: '/settings/users',
      },
      {
        title: '関係者管理',
        icon: 'mdi-account-tie',
        group: 'systems',
        to: '/settings/partners',
      },
      {
        title: '契約書類テンプレート管理',
        icon: 'mdi-folder-edit',
        group: 'systems',
        to: '/settings/templates',
      },
      {
        title: '契約拡張情報管理',
        icon: 'mdi-text-box-check-outline',
        group: 'systems',
        to: '/settings/contract_extra_info',
      },
      {
        title: 'その他設定',
        icon: 'mdi-format-list-bulleted-square',
        group: 'systems',
        to: '/settings/preference',
      },
    ],
  },
  {
    group: 'articles',
    subheader: '物件管理',
    member: [
      {
        title: '物件一覧',
        icon: 'mdi-home-city-outline',
        group: 'articles',
        to: '/settings/articles',
      },
    ],
  },
  {
    group: 'aggregation',
    subheader: '集計',
    member: [
      {
        title: '電子署名（集計）',
        icon: 'mdi-book-search-outline',
        group: 'aggregation',
        to: '/settings/aggregation',
        exact: true,
      },
      {
        title: '配布書類セット（集計）',
        icon: 'mdi-file-search-outline',
        group: 'aggregation',
        to: '/settings/aggregation/distribution_documents',
      },
    ],
  },
  {
    group: 'log',
    subheader: 'ログ',
    member: [
      {
        title: '操作ログ',
        icon: 'mdi-shoe-print',
        group: 'log',
        to: '/settings/operation_log',
      },
      {
        title: '監査ログ',
        icon: 'mdi-shield-search',
        group: 'log',
        to: '/settings/audit_log',
      },
    ],
  },
]

// 物件管理者
export const settingsItem = [
  {
    group: 'articles',
    subheader: '物件管理',
    member: [
      {
        title: '物件一覧',
        icon: 'mdi-home-city-outline',
        group: 'articles',
        to: '/settings/articles',
      },
    ],
  },
  {
    group: 'aggregation',
    subheader: '集計',
    member: [
      {
        title: '電子署名（集計）',
        icon: 'mdi-book-search-outline',
        group: 'log',
        to: '/settings/aggregation',
        exact: true,
      },
    ],
  },
  {
    group: 'log',
    subheader: 'ログ',
    member: [
      {
        title: '監査ログ',
        icon: 'mdi-shield-search',
        group: 'log',
        to: '/settings/audit_log',
      },
    ],
  },
]
